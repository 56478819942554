import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL
const baseDomain = process.env.VUE_APP_API_URL; 
const baseURL = `${baseDomain}`;
const docstorage= process.env.VUE_APP_DOCSTORAGE_API;
const dstorageAPI = `${docstorage}`;
const baseCommonURL = process.env.VUE_APP_COMMON_API;
const baseURLCommon = `${baseCommonURL}`;
const baseEventURL = process.env.VUE_APP_EVENT_API;
const baseURLEvent = `${baseEventURL}`;
const approvalAPI=process.env.VUE_APP_APPROVAL_API
const baseURLApproval=`${approvalAPI}`;
const companySetupAPI=process.env.VUE_APP_COMPANYSETUP_URL;
const baseURLCompanysetup=`${companySetupAPI}`


const instance = axios.create({
  baseURL,
});
const instanceModals = axios.create({
    baseURL,
  });
const instanceCommon = axios.create({
    baseURL:baseURLCommon,
  });
  
const instanceEvent = axios.create({
    baseURL:baseURLEvent,
  });
  const docstrage =axios.create({
    baseURL:dstorageAPI,
});
const instanceApproval =axios.create({
    baseURL:baseURLApproval,
});
const instanceCompanysetup=axios.create({
    baseURL:baseURLCompanysetup,
});

instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/ImportContactsPost' || config.url=='/AddProduct'?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//Use for if accept modal in backend
instanceModals.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
docstrage.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instanceEvent.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = config.url=='/Project/Post'||config.url=='/Project/Create'?"multipart/form-data":"application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instanceApproval.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["content-type"] = "application/json;charset=UTF-8"
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
instanceCompanysetup.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/ImportContactsPost' || config.url=='/AddProduct'?"multipart/form-data":  "application/json;charset=UTF-8"|| config.url=='/Addnew'?"multipart/form-data":  "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {instance,instanceCommon,instanceEvent,instanceModals,docstrage,instanceApproval,instanceCompanysetup};
